import styled from 'styled-components'

const List = styled.ul`
  margin: 2rem 0;
  line-height: 1.5;
  font-size: 0.875rem;
  list-style: disc;
  padding-left: 2rem;
`

export default List
