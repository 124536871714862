import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import SerifText from './SerifText'

type Props = {
  centered?: boolean
  children: ReactNode
}

function Heading({ children, ...props }: Props) {
  return (
    <StyledHeading as="h2" {...props}>
      {children}
    </StyledHeading>
  )
}

export default Heading

const StyledHeading = styled(SerifText)<{ centered?: boolean }>`
  color: ${props => props.theme.colors.highlight};
  font-size: 2.5rem;
  margin: 1em 0;
  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}
`
