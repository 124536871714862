import React from 'react'
import styled from 'styled-components'
import FlairText from './FlairText'

export const BOX_SHADOW_WIDTH_PIXELS = 8

function Box({ children, ...props }) {
  return (
    <Container {...props}>
      <Text>{children}</Text>
    </Container>
  )
}

export default Box

const Container = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  margin: 0;
  padding: 1rem 1.125rem;
  text-align: center;
  box-shadow: ${BOX_SHADOW_WIDTH_PIXELS}px ${BOX_SHADOW_WIDTH_PIXELS}px #404040;
  font-size: 0.75rem;
`

const Text = styled(FlairText)`
  font-size: 0.875rem;
`
