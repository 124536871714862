import { format, isFuture } from 'date-fns'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks'
import React, { useState } from 'react'
import Script from 'react-load-script'
import styled from 'styled-components'
import Anchor from '../components/Anchor'
import Box from '../components/Box'
import Event from '../components/Event'
import FlairText from '../components/FlairText'
import Heading from '../components/Heading'
import Layout from '../components/Layout'
import Lead from '../components/Lead'
import List from '../components/List'
import ListItem from '../components/ListItem'
import PagePadding from '../components/PagePadding'
import PageWide from '../components/PageWide'
import Paragraph from '../components/Paragraph'
import Seo from '../components/Seo'
import SerifText from '../components/SerifText'
// import WorkshopGiftCertificatePurchase from '../components/WorkshopGiftCertificatePurchase'
import Testimonial from '../components/Testimonial'
import TextStyle from '../components/TextStyle'
import PageData from '../types/PageData'

type Props = {
  data: {
    sanityPage: PageData
    workshopsData: {
      edges: {
        node: {
          id: string
          eventbriteId?: string
          eventbriteLink?: string
          universeLink?: string
          venue: {
            name: string
          }
          startDatetime: string
          endDatetime: string
        }
      }[]
    }
    beginnerWorkshopsImage: FileNode
  }
}

function WorkshopsPage({
  data: { sanityPage, workshopsData, beginnerWorkshopsImage },
}: Props) {
  const workshops = workshopsData.edges
    .map(edge => edge.node)
    .filter(workshop => isFuture(workshop.startDatetime))

  const [isEventbriteLoaded, setIsEventbriteLoaded] = useState(false)

  return (
    <Layout pageTitle="Workshops" pageData={sanityPage}>
      <Seo
        title="Workshops"
        description="Learn modern calligraphy while meeting new friends, online in a virtual calligraphy class, or hire Cody for a private calligraphy group session."
      />
      <Script
        url="https://www.eventbrite.ca/static/widgets/eb_widgets.js"
        onLoad={() => setIsEventbriteLoaded(true)}
      />
      <Script url="https://www.universe.com/embed2.js" />
      <BeginnerGrid as="section">
        <div>
          <HeadingWithLayout>
            Virtual &amp; In-Person Calligraphy Classes
          </HeadingWithLayout>
          <LeadWithLayout>Learning calligraphy begins here.</LeadWithLayout>
          <Paragraph>
            Cody Calligraphy's signature workshop,{' '}
            <TextStyle strong>Modern Calligraphy for Beginners</TextStyle> is
            the best starting point for those who are interested in modern
            calligraphy, but aren't quite sure where to begin. Or join her{' '}
            <TextStyle strong>Intermediate</TextStyle> workshop to take your
            skills to the next level.
          </Paragraph>
          <Paragraph>
            Professional calligrapher Cody McCallum offers guided instruction
            online and in-person. Corporate workshops are available, and
            calligraphy starter kits can be created for your group for an
            additional fee.
          </Paragraph>
        </div>
        <GatsbyImage
          image={getImage(beginnerWorkshopsImage)}
          alt="Toronto Calligraphy Instructor demonstrates modern calligraphy for a group of students in a flower shop and cafe."
        />
        <div>
          <BigBox>Who should take this workshop?</BigBox>
          <ListWithLayout>
            <ListItem>
              DIY enthusiasts{' '}
              <span role="img" aria-label="envelope with heart emoji">
                💌
              </span>{' '}
              who want to add personalization to gifts, dinner parties, snail
              mail, the list goes on!
            </ListItem>
            <ListItem>
              engaged folks{' '}
              <span role="img" aria-label="ring">
                💍
              </span>{' '}
              who want to make their own wedding place cards &amp; stationery.
            </ListItem>
            <ListItem>
              friends{' '}
              <span role="img" aria-label="coffee emoji">
                ☕️
              </span>{' '}
              who want to get together for a coffee or tea and learn a fun new
              skill.
            </ListItem>
            <ListItem>
              humans who are bad at meditating{' '}
              <span role="img" aria-label="sweat smile emoji">
                😅
              </span>{' '}
              and could benefit from a structured mindfulness practice.
            </ListItem>
          </ListWithLayout>
        </div>
        <div>
          <Paragraph>
            Cody is also available for corporate workshops, staff appreciation
            &amp; mindfulness events, experiential marketing activations,
            in-store and live calligraphy experiences, birthdays, wedding
            showers, and more!
          </Paragraph>
          <Paragraph>
            To inquire about availability and pricing,{' '}
            <Anchor as={Link} to="/contact">
              get in touch
            </Anchor>
            !
          </Paragraph>
        </div>
        {workshops.length ? (
          <GridWide>
            <SubSectionHeading as="h3">Upcoming Workshops</SubSectionHeading>
            <WorkshopList>
              {workshops.map(date => {
                const {
                  id,
                  startDatetime,
                  endDatetime,
                  venue: { name },
                  eventbriteId,
                  eventbriteLink,
                  universeLink,
                } = date
                return (
                  <Event
                    key={id}
                    as="li"
                    day={format(startDatetime, 'D')}
                    month={format(startDatetime, 'MMM')}
                    time={`${format(startDatetime, 'h:mma')} - ${format(
                      endDatetime,
                      'h:mma'
                    )}`}
                    eventbrite={{
                      eventId: eventbriteId,
                      link: eventbriteLink,
                    }}
                    isEventbriteLoaded={isEventbriteLoaded}
                    universe={{
                      link: universeLink,
                    }}
                    location={name}
                  />
                )
              })}
            </WorkshopList>
          </GridWide>
        ) : null}
        <GridCallout>
          <TestimonialWithLayout author="Michelle S." stars={5}>
            <TestimonialText>
              &ldquo;This was one of the best workshops I have attended. I
              learned a lot! Cody is a great instructor who is encouraging,
              inclusive and funny. If you are looking to learn brush
              calligraphy, take the leap and sign up for one of Cody&rsquo;s
              workshops.&rdquo;
            </TestimonialText>
          </TestimonialWithLayout>
        </GridCallout>
      </BeginnerGrid>
    </Layout>
  )
}

export default WorkshopsPage

export const query = graphql`
  query WorkshopsPageQuery {
    sanityPage(uuid: { eq: "workshops" }) {
      _rawPageMessage
    }
    workshopsData: allSanityWorkshop(
      sort: { fields: startDatetime, order: ASC }
    ) {
      edges {
        node {
          id
          eventbriteId
          eventbriteLink
          universeLink
          venue {
            name
          }
          startDatetime
          endDatetime
        }
      }
    }
    beginnerWorkshopsImage: file(
      relativePath: { eq: "workshops-page-calligraphy-workshop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          height: 400
          quality: 50
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`

const HeadingWithLayout = styled(Heading)`
  margin-top: 0;
  margin-bottom: 64px;
`

const LeadWithLayout = styled(Lead)`
  margin-bottom: 24px;
`

const BeginnerGrid = styled(PagePadding)`
  padding-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 3rem;

  @media (max-width: 825px) {
    grid-template-columns: 1fr;
  }
`

const BigBox = styled(Box)`
  font-size: 1.125rem;
`

const ListWithLayout = styled(List)`
  margin-bottom: -1rem;
`

const GridWide = styled.div`
  grid-column: 1 / -1;
`

const SubSectionHeading = styled(FlairText)`
  font-size: 1.125rem;
  text-align: center;
  margin: 0 0 28px 0;
`

const WorkshopList = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-right: -16px;
  margin-top: 32px;
`

const TestimonialWithLayout = styled(Testimonial)`
  color: ${({ theme }) => theme.colors.text};
  padding: 0 0 3rem;
`

const TestimonialText = styled(SerifText)``

const GridCallout = styled(PageWide)`
  grid-column: 1 / -1;
`

// Used to be used for a grey backgrounded banner for virtual workshop
// const Banner = styled(GridCallout)`
//   background: ${({ theme }) => theme.colors.offWhite};
//   padding: 28px;
//   text-align: center;
// `
