import styled, { css } from 'styled-components'

const TextStyle = styled.span<{ em?: boolean; strong?: boolean }>`
  ${({ em }) =>
    em &&
    css`
      font-style: italic;
    `}
  ${({ strong }) =>
    strong &&
    css`
      font-weight: 600;
    `}
`

export default TextStyle
