import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import PagePadding from '../components/PagePadding'
import Paragraph from '../components/Paragraph'
import { getLinkConfig } from '../utils'
import LinkConfig from '../types/LinkConfig'
import Lead from './Lead'
import SerifText from './SerifText'

type Props = {
  author: string
  stars?: number
  ratingLink?: {
    displayText: string
    url: string
  }
}

const Testimonial: React.FC<Props> = ({
  author,
  children,
  stars = 0,
  ratingLink,
  ...otherProps
}) => {
  const starsMarkup: JSX.Element[] = []
  for (let i = 0; i < stars; i++) {
    starsMarkup.push(<ReviewIcon key={i} icon={faStar} />)
  }
  let ratingLinkConfig: LinkConfig
  if (ratingLink) {
    ratingLinkConfig = getLinkConfig(ratingLink.url)
  }
  return (
    <CallOut {...otherProps}>
      <CallOutContent>
        <Paragraph>
          {starsMarkup}
          {ratingLink && (
            <ReviewLink
              as={ratingLinkConfig.component}
              href={
                ratingLinkConfig.isExternal ? ratingLinkConfig.url : undefined
              }
              to={
                ratingLinkConfig.isInternal ? ratingLinkConfig.url : undefined
              }
              rel={
                ratingLinkConfig.isExternal ? 'noopener noreferrer' : undefined
              }
              target={ratingLinkConfig.isExternal ? '_blank' : undefined}
            >
              {ratingLink.displayText}
            </ReviewLink>
          )}
        </Paragraph>
        <MainContent>{children}</MainContent>
        <Lead>{author}</Lead>
      </CallOutContent>
    </CallOut>
  )
}

export default Testimonial

const CallOut = styled.div`
  padding: 0.01rem 0;
  text-align: center;
  display: flex;

  @media (min-width: 450px) {
    font-size: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`

const CallOutContent = styled(PagePadding)`
  margin: auto;
`

const ReviewIcon = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 1.5rem;
  margin: 0 0.5rem;
`

const ReviewLink = styled(SerifText)`
  font-style: normal;
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.9em;
  text-decoration: underline;
`

const MainContent = styled.div`
  margin: 1.5rem 0;
`
