import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faClock,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'

type EventbriteConfig = {
  link: string
  eventId: string
}

type UniverseConfig = {
  link: string
}

type Props = {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  className?: string
  day: string
  eventbrite?: EventbriteConfig
  isEventbriteLoaded?: boolean
  universe?: UniverseConfig
  month: string
  time: string
  location: string
}

function Event({
  as,
  className,
  day,
  eventbrite,
  isEventbriteLoaded,
  universe,
  month,
  time,
  location,
}: Props) {
  const { eventId, link } = eventbrite
  const triggerId = `eventbrite-widget-modal-trigger-${eventId}`
  const triggerRef = useRef<HTMLElement>(null)
  const [
    isEventbriteEventInitialized,
    setIsEventbriteEventInitialized,
  ] = useState(false)

  useEffect(() => {
    if (
      eventbrite.link &&
      isEventbriteLoaded &&
      !isEventbriteEventInitialized
    ) {
      const exampleCallback = function() {
        return
      }

      window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId,
        modal: true,
        modalTriggerElementId: triggerId,
        onOrderComplete: exampleCallback,
      })

      setIsEventbriteEventInitialized(true)
    }
  })

  const handleKeyDown = e => {
    if (e.key === 'Enter' && triggerRef.current) {
      triggerRef.current.click()
    }
  }

  const eventMarkup = (
    <>
      <Date>
        <Month>{month}</Month>
        <Day>{day}</Day>
      </Date>
      <Details>
        <Detail>
          <Icon fixedWidth icon={faMapMarkerAlt}></Icon>
          <VenueName>{location}</VenueName>
        </Detail>
        <Detail>
          <Icon fixedWidth icon={faClock}></Icon>
          <DisplayTime>{time}</DisplayTime>
        </Detail>
        <CallToAction>
          Register <Icon icon={faArrowRight}></Icon>
        </CallToAction>
      </Details>
    </>
  )

  return (
    <Container as={as} className={className}>
      {eventbrite.link && !universe.link && (
        <EventbriteTrigger
          role="button"
          tabIndex={0}
          id={triggerId}
          onKeyDown={handleKeyDown}
          ref={triggerRef}
        >
          <noscript>
            <a href={link} rel="noopener noreferrer" target="_blank">
              Buy Tickets on Eventbrite
            </a>
          </noscript>
          {eventMarkup}
        </EventbriteTrigger>
      )}
      {universe.link && !eventbrite.link && (
        <EventbriteTrigger as="a" href={universe.link} role="button">
          {eventMarkup}
        </EventbriteTrigger>
      )}
    </Container>
  )
}

export default Event

const Container = styled.div`
  width: 280px;
  margin-right: 16px;
  margin-bottom: 16px;
`

const EventbriteTrigger = styled.span`
  border: 2px dashed ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: 0;
  padding: 0.625rem;
  text-align: unset;
  width: 100%;
  transition: border-color 0.2s, transform 0.1s;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.tertiary};
    transform: scale(1.035);
    outline: none;
  }
`

const Date = styled.div`
  font-weight: 600;
  grid-column: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.tertiary};
`

const Month = styled.span`
  font-size: 1rem;
  display: block;
  text-transform: uppercase;
`

const Day = styled.span`
  display: block;
  font-size: 1.25rem;
`

const Details = styled.div`
  font-size: 0.875rem;
  font-weight: 300;
  margin-left: 6px;
  grid-column: 2 / span 3;
`

const Detail = styled.div`
  margin-bottom: 0.4em;
`

const VenueName = styled.span``

const DisplayTime = styled.span`
  font-size: 0.85em;
`

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.375em;
`

const CallToAction = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  margin-left: ${1.25 + 0.375}em;
`
